import React, { useState } from 'react';
import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  Grid,
} from '@mui/material';
import axios from 'axios'; // Axios kütüphanesini ekleyin

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleLogin = () => {
    // API'ye post isteği gönderme
    axios.post('http://198.177.125.163/api/login', JSON.stringify({
      username: username,
      password: password,
    }))
    .then(response => {
      const data = response.data;
      if (data.code === 200) {
        // Başarılı giriş durumunda verileri localStorage'a kaydet
        localStorage.setItem('id', data.id);
        localStorage.setItem('token', data.token);
        localStorage.setItem('tokendate', data.tokendate);
      } else {
        setError('Kullanıcı adı veya şifre yanlış.');
      }
    })
    .catch(error => {
      setError('Bir hata oluştu. Lütfen tekrar deneyin.');
    });
  };

  return (
    <div
      style={{
        background: 'url("http://www.brandlifemag.com/wp-content/uploads/2018/01/Fractal-%C4%B0stanbul-2.jpg")',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative',
        height: '100vh',
        display:'flex',
        justifyContent:'center',
        alignContent:'center',
        alignItems:'center',
        opacity: 1,
      }}
    >
      <Container maxWidth="xs">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: 'rgba(255, 255, 255, 1)',
            padding: '16px',
            borderRadius: '8px',
          }}
        >
          <Typography variant="h4" gutterBottom>
            Giriş Yap
          </Typography>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Kullanıcı Adı"
                  variant="outlined"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Şifre"
                  variant="outlined"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </Grid>
            </Grid>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleLogin}
              style={{ marginTop: '16px' }}
            >
              Giriş Yap
            </Button>
          </form>
          {error && (
            <Typography
              variant="body2"
              color="error"
              style={{ marginTop: '8px' }}
            >
              {error}
            </Typography>
          )}
        </Box>
      </Container>
    </div>
  );
}

export default Login;
