import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import MainPage from './pages/main';
import  Login  from './pages/login';
import KazaTespitTutanagi from './pages/kttview';

function App() {
  return (
    <Router>
    <Switch>
    <Route path="/login" component={Login} />
      <Route path="/" component={MainPage} />
      <Route path="/kttview/:server/:id" component={KazaTespitTutanagi} />
      
    </Switch>
  </Router>
  );
}

export default App;
