import React, { useState } from 'react';
import {
  AppBar,
  Box,
  Button,
  Container,
  CssBaseline,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tab,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DamagePage from './damage'; // damage.js dosyasını kullan
import SorgularPage from './sorgular';
function MainPage() {
  const [value, setValue] = useState(0);
  const [selectAll, setSelectAll] = useState(false);
  const [checkedItems, setCheckedItems] = useState({});
  const [selectedAction, setSelectedAction] = useState(''); // Seçili işlem

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    const updatedCheckedItems = {};
    customersData.forEach((customer) => {
      updatedCheckedItems[customer.id] = !selectAll;
    });
    setCheckedItems(updatedCheckedItems);
  };

  const handleCheckboxChange = (customerId) => {
    const updatedCheckedItems = { ...checkedItems };
    updatedCheckedItems[customerId] = !checkedItems[customerId];
    setCheckedItems(updatedCheckedItems);
  };

  // Dummy veri
  const customersData = [
    { id: 1, name: 'John Doe', tc: '1234567890', tax: '456789', plate: 'AB123' },
    { id: 2, name: 'Jane Smith', tc: '0987654321', tax: '987654', plate: 'CD456' },
    // Diğer müşteriler...
  ];

  // Müşteri ekleme işlevi
  const handleAddCustomer = () => {
    // Ekleme işlemini burada gerçekleştirin
    console.log('Müşteri eklendi:', newCustomer);
  };

  // Müşteri düzenleme işlevi
  const handleEditCustomer = (customerId) => {
    // Düzenleme işlemi için gerekli kodu burada ekleyin
    console.log(`Müşteri düzenle: ${customerId}`);
  };

  // Hasarları göster işlevi
  const handleShowDamage = (customerId) => {
    // Hasarları gösterme işlemi için gerekli kodu burada ekleyin
    console.log(`Hasarları Göster: ${customerId}`);
  };

  // Yeni müşteri bilgileri
  const [newCustomer, setNewCustomer] = useState({
    name: '',
    tc: '',
    tax: '',
    plate: '',
  });

  return (
    <div>
      <CssBaseline />
      <AppBar position="static">
      <Tabs
  value={value}
  onChange={handleChange}
  TabIndicatorProps={{
    style: { backgroundColor: '#1976d2' }, // Seçili sekmenin altındaki çizgi rengi
  }}
>
  <Tab
    label={
      <span
        style={{
          fontWeight: value === 0 ? 'bold' : 'bold', // Seçili ise kalın, diğerleri normal font kalınlığı
        }}
      >
        Müşteriler
      </span>
    }
    style={{
      backgroundColor: value === 0 ? 'rgb(9, 68, 128)' : 'transparent', // Seçili ise arkaplan mavi, diğerleri şeffaf
      color: value === 0 ? '#fff' : '#fff', // Seçili ise metin beyaz, diğerleri siyah
      '&:hover': {
        backgroundColor: 'rgb(9, 68, 128)', // Hover durumunda arkaplan rengi
        fontWeight: 'bold', // Hover durumunda kalın font
      },
    }}
  />
  <Tab
    label={
      <span
        style={{
          fontWeight: value === 1 ? 'bold' : 'bold', // Seçili ise kalın, diğerleri normal font kalınlığı
        }}
      >
        Hasarlar
      </span>
    }
    style={{
      backgroundColor: value === 1 ? 'rgb(9, 68, 128)' : 'transparent', // Seçili ise arkaplan mavi, diğerleri şeffaf
      color: value === 1 ? '#fff' : '#fff', // Seçili ise metin beyaz, diğerleri siyah
      '&:hover': {
        backgroundColor: 'rgb(9, 68, 128)', // Hover durumunda arkaplan rengi
        fontWeight: 'bold', // Hover durumunda kalın font
      },
    }}
  />
  <Tab
    label={
      <span
        style={{
          fontWeight: value === 2 ? 'bold' : 'bold', // Seçili ise kalın, diğerleri normal font kalınlığı
        }}
      >
        Sorgulamalar
      </span>
    }
    style={{
      backgroundColor: value === 2 ? 'rgb(9, 68, 128)' : 'transparent', // Seçili ise arkaplan mavi, diğerleri şeffaf
      color: value === 2 ? '#fff' : '#fff', // Seçili ise metin beyaz, diğerleri siyah
      '&:hover': {
        backgroundColor: 'rgb(9, 68, 128)', // Hover durumunda arkaplan rengi
        fontWeight: 'bold', // Hover durumunda kalın font
      },
    }}
  />
</Tabs>
        <Box style={{ marginLeft: 'auto', right: '20px',top:10 ,position:'absolute' }}>
          <Typography variant="h6" color="inherit">
            Bakiye: $1000
          </Typography>
        </Box>
      </AppBar>
      <TabPanel value={value} index={0}>
        <Container maxWidth="lg">
          <Typography variant="h4" gutterBottom>
            Müşteriler
          </Typography>
          <Grid container spacing={2}>
  <Grid item xs={12} sm={3}>
    <TextField
      fullWidth
      label="Müşteri Ad Soyad veya Unvan"
      value={newCustomer.name}
      onChange={(e) =>
        setNewCustomer({ ...newCustomer, name: e.target.value })
      }
    />
  </Grid>
  <Grid item xs={12} sm={2}>
    <TextField
      fullWidth
      label="TC"
      value={newCustomer.tc}
      onChange={(e) =>
        setNewCustomer({ ...newCustomer, tc: e.target.value })
      }
    />
  </Grid>
  <Grid item xs={12} sm={2}>
    <TextField
      fullWidth
      label="Vergi"
      value={newCustomer.tax}
      onChange={(e) =>
        setNewCustomer({ ...newCustomer, tax: e.target.value })
      }
    />
  </Grid>
  <Grid item xs={12} sm={2}>
    <TextField
      fullWidth
      label="Plaka"
      value={newCustomer.plate}
      onChange={(e) =>
        setNewCustomer({ ...newCustomer, plate: e.target.value })
      }
    />
  </Grid>
  <Grid item xs={12} sm={1}>
    <IconButton
      color="primary"
      onClick={handleAddCustomer}
      style={{ marginTop: '16px' }}
    >
      <AddIcon />
    </IconButton>
  </Grid>
  <Grid item xs={12} sm={1}>
    <IconButton
      color="primary"
      style={{ marginTop: '16px' }}
    >
      <AttachFileIcon />
    </IconButton>
  </Grid>
</Grid>
          <TableContainer component={Paper} mt={4}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectAll}
                          onChange={handleSelectAll}
                        />
                      }
                      label="Tümünü Seç"
                    />
                  </TableCell>
                  <TableCell>Müşteri Ad Soyad veya Unvan</TableCell>
                  <TableCell>TC</TableCell>
                  <TableCell>Vergi</TableCell>
                  <TableCell>Plaka</TableCell>
                  <TableCell>İşlemler</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {customersData.map((customer) => (
                  <TableRow key={customer.id}>
                    <TableCell>
                      <Checkbox
                        checked={checkedItems[customer.id] || false}
                        onChange={() => handleCheckboxChange(customer.id)}
                      />
                    </TableCell>
                    <TableCell>{customer.name}</TableCell>
                    <TableCell>{customer.tc}</TableCell>
                    <TableCell>{customer.tax}</TableCell>
                    <TableCell>{customer.plate}</TableCell>
                    <TableCell>
                      <Button
                        variant="outlined"
                        color="primary" // "default" yerine "primary" kullan
                        onClick={() => handleEditCustomer(customer.id)}
                      >
                        Düzenle
                      </Button>
                      <Button
                        variant="outlined"
                        color="secondary" // "default" yerine "primary" kullan
                        onClick={() => handleShowDamage(customer.id)}
                      >
                        Hasarları Göster
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box mt={2}>
            <FormControl fullWidth>
              <InputLabel id="action-label">İşlemi Uygula</InputLabel>
              <Select
                labelId="action-label"
                id="action-select"
                value={selectedAction}
                onChange={(e) => setSelectedAction(e.target.value)}
              >
                <MenuItem value="sil">Sil</MenuItem>
              </Select>
            </FormControl>
            <Button
              variant="contained"
              color="primary" // "default" yerine "primary" kullan
              style={{ marginTop: '16px' }}
            >
              İşlemi Uygula
            </Button>
          </Box>
        </Container>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <DamagePage /> {/* damage.js dosyasını kullanın */}
      </TabPanel>
      <TabPanel value={value} index={2}>
  <SorgularPage />
</TabPanel>
    </div>
  );
}

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

export default MainPage;
