import React, { useState } from 'react';
import {
  Box,
  Container,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  Grid,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/system';

const StyledTableContainer = styled(TableContainer)`
  background-color: #fbfcfd;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px 5px 0 0;
  padding: 8px 16px;
`;

const StyledTableHead = styled(TableHead)`
  background-color: #3bd;
  font-weight: bold;
`;

const StyledTableCell = styled(TableCell)`
  border-bottom: 2px solid #ggg;
  color: #fff;
  bacground-color: #3bd;
  font-weight: 600;
`;

function SorgularPage() {
  const [tcAccordionExpanded, setTcAccordionExpanded] = useState(false);
  const [taxAccordionExpanded, setTaxAccordionExpanded] = useState(false);
  const [plateAccordionExpanded, setPlateAccordionExpanded] = useState(false);
  const [chassisAccordionExpanded, setChassisAccordionExpanded] = useState(
    false
  );
  const handleDetaylarClick = (server,id) => {
    // Detaylar butonuna tıklandığında /kktview sayfasına yönlendir
    window.open('/kttview/'+server+'/'+id,'_blank');
  };

  const [tcResults, setTcResults] = useState([]);
  const [taxResults, setTaxResults] = useState([]);
  const [plateResults, setPlateResults] = useState([]);
  const [chassisResults, setChassisResults] = useState([]);

  const handleAccordionChange = (sorgu) => {
    switch (sorgu) {
      case 'tc':
        setTcAccordionExpanded(!tcAccordionExpanded);
        break;
      case 'tax':
        setTaxAccordionExpanded(!taxAccordionExpanded);
        break;
      case 'plate':
        setPlateAccordionExpanded(!plateAccordionExpanded);
        break;
      case 'chassis':
        setChassisAccordionExpanded(!chassisAccordionExpanded);
        break;
      default:
        break;
    }
  };

  const handleSorgula = (sorgu) => {
    const apiUrl = 'http://198.177.125.163/sportalapi.php';
    const islemParam = `${sorgu}ilesorgula`;
    const inputValue = document.getElementById(`${sorgu}-input`).value;

    const formData = new FormData();
    formData.append('islem', islemParam);
    formData.append('TC', inputValue);

    fetch(apiUrl, {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        switch (sorgu) {
          case 'tc':
            setTcResults(data);
            break;
          case 'tax':
            setTaxResults(data);
            break;
          case 'plate':
            setPlateResults(data);
            break;
          case 'chassis':
            setChassisResults(data);
            break;
          default:
            break;
        }
      })
      .catch((error) => {
        console.error('Sorgu sırasında bir hata oluştu:', error);
      });
  };

  const renderTable = (results) => {
    return (
      <>
        <Typography variant="h6">Kaza Listesi</Typography>
        <StyledTableContainer component={Paper}>
          <Table>
            <StyledTableHead>
              <TableRow>
                <StyledTableCell>TC/VERGI</StyledTableCell>
                <StyledTableCell>İhbar No</StyledTableCell>
                <StyledTableCell>Kaza Tarihi</StyledTableCell>
            \    <StyledTableCell>İşlem</StyledTableCell>
              </TableRow>
            </StyledTableHead>
            <TableBody>
              {results.map((result, index) => (
                <TableRow key={index}>
                  <TableCell>{result.kimlikNo} / {result.vergiNo}</TableCell>
                  <TableCell>{result.kazaSiraNo}</TableCell>
                  <TableCell>{result.kazaTarihi}</TableCell>
                  <TableCell>{result.durum}</TableCell>
                  <TableCell>
                    <Button variant="outlined" color="primary" onClick={()=>handleDetaylarClick(result.server,result.kazaSiraNo)}>
                      Detaylar
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </StyledTableContainer>
      </>
    );
  };

  return (
    <div>
      <Box
        sx={{
          background: 'linear-gradient(to bottom, #ddd, #fff)',
          minHeight: '100vh',
          color: 'black',
          py: 4,
        }}
      >
        <Container maxWidth="lg">
          <Accordion
            expanded={tcAccordionExpanded}
            onChange={() => handleAccordionChange('tc')}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">TC ile Kaza Sorgulama</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="TC Kimlik No"
                    variant="outlined"
                    id="tc-input"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleSorgula('tc')}
                  >
                    Sorgula
                  </Button>
                </Grid>
                {tcResults.length > 0 && (
                  <Grid item xs={12}>
                    {renderTable(tcResults)}
                  </Grid>
                )}
              </Grid>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={taxAccordionExpanded}
            onChange={() => handleAccordionChange('tax')}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">Vergi ile Kaza Sorgulama</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Vergi No"
                    variant="outlined"
                    id="tax-input"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleSorgula('tax')}
                  >
                    Sorgula
                  </Button>
                </Grid>
                {taxResults.length > 0 && (
                  <Grid item xs={12}>
                    {renderTable(taxResults)}
                  </Grid>
                )}
              </Grid>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={plateAccordionExpanded}
            onChange={() => handleAccordionChange('plate')}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">Plaka ile Kaza Sorgulama</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Plaka"
                    variant="outlined"
                    id="plate-input"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleSorgula('plate')}
                  >
                    Sorgula
                  </Button>
                </Grid>
                {plateResults.length > 0 && (
                  <Grid item xs={12}>
                    {renderTable(plateResults)}
                  </Grid>
                )}
              </Grid>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={chassisAccordionExpanded}
            onChange={() => handleAccordionChange('chassis')}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">Şase ile Kaza Sorgulama</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Şase No"
                    variant="outlined"
                    id="chassis-input"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleSorgula('chassis')}
                  >
                    Sorgula
                  </Button>
                </Grid>
                {chassisResults.length > 0 && (
                  <Grid item xs={12}>
                    {renderTable(chassisResults)}
                  </Grid>
                )}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Container>
      </Box>
    </div>
  );
}

export default SorgularPage;
