import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Checkbox,
  FormControlLabel,
  CircularProgress,
} from '@mui/material';
import axios from 'axios';
import qs from 'qs';
import { useHistory } from 'react-router-dom';

function DamagePage() {
  const [selectedAction, setSelectedAction] = useState('');
  const [selectAll, setSelectAll] = useState(false);
  const [checkedItems, setCheckedItems] = useState({});
  const [damageData, setDamageData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loaded, setLoaded] = useState(false); // İlk yükleme durumu kontrolü
  const history = useHistory();

  const handleDetaylarClick = (server,id) => {
    // Detaylar butonuna tıklandığında /kktview sayfasına yönlendir
    window.open('/kttview/'+server+'/'+id,'_blank');
  };
  useEffect(() => {
    if (!loaded) { // İlk yükleme durumu kontrolü
      const postData = qs.stringify({
        islem: 'hasarlarigetir',
      });

      axios
        .post('http://198.177.125.163/sportalapi.php', postData, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        })
        .then((response) => {
          setDamageData(response.data);
          setLoading(false);
          setLoaded(true); // İlk yüklemeyi tamamladık
        })
        .catch((error) => {
          console.error('POST isteği sırasında bir hata oluştu:', error);
          setLoading(false);
        });
    }
  }, [loaded]); // loaded değiştiğinde çalışacak

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    const updatedCheckedItems = {};
    damageData.forEach((damage) => {
      updatedCheckedItems[damage.id] = !selectAll;
    });
    setCheckedItems(updatedCheckedItems);
  };

  const handleCheckboxChange = (damageId) => {
    const updatedCheckedItems = { ...checkedItems };
    updatedCheckedItems[damageId] = !checkedItems[damageId];
    setCheckedItems(updatedCheckedItems);
  };

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" gutterBottom>
        Hasarlar
      </Typography>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="60vh">
          <CircularProgress />
        </Box>
      ) : (
        <>
          <FormControlLabel
            control={
              <Checkbox
                checked={selectAll}
                onChange={handleSelectAll}
              />
            }
            label="Tümünü Seç"
          />
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>TC/Vergi No</TableCell>
                  <TableCell>Ihbar No</TableCell>
                  <TableCell>Kaza Tarihi</TableCell>
                  <TableCell>Durum</TableCell>
                  <TableCell>İşlemler</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {damageData.map((damage) => (
                  <TableRow key={damage.id}>
                    <TableCell>
                      <Checkbox
                        checked={checkedItems[damage.id] || false}
                        onChange={() => handleCheckboxChange(damage.id)}
                      />
                    </TableCell>
                    <TableCell>{damage.kimlikNo} / {damage.vergiNo}</TableCell>
                    <TableCell>{damage.kazaSiraNo}</TableCell>
                    <TableCell>{damage.kazaTarihi}</TableCell>
                    <TableCell>{damage.status}</TableCell>
                    <TableCell>
                      <Button variant="outlined" color="primary"  onClick={()=>handleDetaylarClick(damage.server,damage.kazaSiraNo)}>
                        Detaylar
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box mt={2}>
            <FormControl fullWidth>
              <InputLabel id="action-label">İşlemi Uygula</InputLabel>
              <Select
                labelId="action-label"
                id="action-select"
                value={selectedAction}
                onChange={(e) => setSelectedAction(e.target.value)}
              >
                <MenuItem value="sil">Sil</MenuItem>
              </Select>
            </FormControl>
            <Button
              variant="contained"
              color="primary"
              style={{ marginTop: '16px' }}
            >
              İşlemi Uygula
            </Button>
          </Box>
        </>
      )}
    </Container>
  );
}

export default DamagePage;
