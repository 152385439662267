
import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  CircularProgress,
  Strong
} from '@mui/material';
import { useParams } from 'react-router-dom';
import axios from 'axios';
function KazaTespitTutanagi() {
    const { id,server } = useParams(); // URL'den ID'yi al
    const [loading, setLoading] = useState(true);
    const [veriler, setVeriler] = useState(null);
    const [genelBilgiler,setGenelBigiler] = useState(null);
    const [ihbar,setIhbar] = useState(null);
    const [kusurlar,setKusurlar] = useState(null);
    const [kusurlar2,setKusurlar2] = useState(null);
    const [araclar,setAraclar] = useState(null);
 
    useEffect(() => {
    axios.post('http://198.177.125.163/sportalapi.php', {
        server: server,
        id: id,
        islem: 'kttview',
      },{headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      }})
      .then((response) => {
        // Yanıtı alın ve state'i güncelleyin
       
        setVeriler(response.data)
        let veri = response.data;
        setGenelBigiler(veri.genelBilgiler);
        setIhbar(veri.ihbar);
        setAraclar(veri.araclar);
        setKusurlar(veri.kusurlar);
        setKusurlar2(veri.kusurlar2);
 
    setLoading(false);
      })
      .catch((error) => {
        console.error('Hata:', error);
      });
    }, [id,server]); // id parametresi değiştiğinde bu efekt çalışır
   
  
if(loading == true){
    return(
        <Box display="flex" justifyContent="center" alignItems="center" height="60vh">
          <CircularProgress />
        </Box>
      )
}
if(loading == false && server =='kttdb1'){
  return (
    <div>
      <Box
        sx={{
          background: 'linear-gradient(to bottom, #f0f0f0, #ffffff)',
          minHeight: '100vh',
          color: 'black',
          py: 4,
        }}
      >
        <Container maxWidth="lg">
          <Typography variant="h4" gutterBottom>
            Kaza Tespit Tutanağı Genel Bilgileri
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <strong>SBM Kaza İhbar No:</strong> {genelBilgiler.kazaSiraNo}
                  </TableCell>
                  <TableCell>
                    <strong>Kaza Tarihi:</strong> {genelBilgiler.kazaTarihi}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <strong>İhbar Tarihi:</strong> {ihbar.ihbarTarihi}
                  </TableCell>
                  <TableCell>
                    <strong>İlk İşlem Tarihi:</strong>{genelBilgiler.ilkIslemTarihi}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <strong>Son İşlem Tarihi:</strong> {genelBilgiler.sonIslemTarihi}
                  </TableCell>
                  <TableCell>
                    <strong>Kaza Yeri İl/İlçe:</strong> 
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <strong>Hasarlı Araç Adedi:</strong> {genelBilgiler.kazaAracAdeti}
                  </TableCell>
                  <TableCell>
                    <strong>İhbarla İlgili Ürün:</strong> {ihbar.ihbariAlinanUrun == 100 && 'Trafik'} {ihbar.ihbariAlinanUrun == 120 && 'Kasko'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <strong>KTT Kayıt Tipi:</strong> {genelBilgiler.kaynakTipi}
                  </TableCell>
                  <TableCell>
                    <strong>Dosya Durumu:</strong> {genelBilgiler.dosyaDurumKodu}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <Typography variant="h6" mt={4} gutterBottom>
            <strong>Araç Bilgileri</strong>
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell><strong>#</strong></TableCell>
                  <TableCell><strong>Plaka</strong></TableCell>
                  <TableCell><strong>Şase No</strong></TableCell>
                  <TableCell><strong>Motor No</strong></TableCell>
                  <TableCell><strong>Sigortalı Vergi/Kimlik No</strong></TableCell>
                  <TableCell><strong>Sürücü İle Sigortalı Aynı Mı</strong></TableCell>
                  <TableCell><strong>Sürücü Kimlik No</strong></TableCell>
                  <TableCell><strong>Sürücü Ad Soyadı</strong></TableCell>
                  <TableCell></TableCell>
              
                </TableRow>
              </TableHead>
              <TableBody>
              {araclar.map((arac) => (
                
                <TableRow>
                    <TableCell>{arac.aracSiraNo}</TableCell>
                  <TableCell>{arac.plakaIlKodu} {arac.plakaNo}</TableCell>
                  <TableCell>{arac.sasiNo}</TableCell>
                  <TableCell>{arac.motorNo}</TableCell>
                  <TableCell>{arac.sigortaliVatandaslikNo != 0 && arac.sigortaliVatandaslikNo}  {arac.sigortaliVergiNo != 0 && arac.sigortaliVergiNo}</TableCell>
                  <TableCell>{arac.surucuSigortaliIleAynimi == 'E' && 'Evet'}
                  {arac.surucuSigortaliIleAynimi == 'H' && 'Hayir'} 
                  </TableCell>
                  <TableCell>{arac.surucuSigortaliIleAynimi == 'H' && arac.surucuVatandaslikNo}</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
              
                </TableRow>
              
                ))}
                 

                
              </TableBody>
            </Table>
          </TableContainer>
          <Typography variant="h6" mt={4} gutterBottom>
            Poliçeler
          </Typography>
          <TableContainer component={Paper}>
          <Table>
              <TableHead>
                <TableRow>
                <TableCell><strong>Plaka</strong></TableCell>
                  <TableCell><strong>Ürün</strong></TableCell>
                  <TableCell><strong>Sigorta Şirket</strong></TableCell>
                  <TableCell><strong>Acente No</strong></TableCell>
                  <TableCell><strong>Poliçe No</strong></TableCell>
                  <TableCell><strong>Yenileme No</strong></TableCell>
                  <TableCell><strong>Poliçe Başlama Tarh</strong></TableCell>
                  <TableCell><strong>Poliçe Bitiş Tarih</strong></TableCell>
                  <TableCell><strong>Sigortalı</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {araclar.map((arac) => (
  arac.policeler.map((police, index) => (
    <TableRow key={index}>
        <TableCell>{arac.plakaIlKodu} {arac.plakaNo}</TableCell>
      <TableCell>{police.urunKodu == 100 && 'Trafik'} {police.urunKodu == 120 && 'Kasko'}</TableCell>
      <TableCell>{police.sirketKodu}</TableCell>
      <TableCell>{police.acenteNo}</TableCell>
      <TableCell>{police.policeNo}</TableCell>
      <TableCell>{police.yenilemeNo}</TableCell>
      <TableCell>{police.policeBaslangicTarihi}</TableCell>
      <TableCell>{police.policeBitisTarihi}</TableCell>
      <TableCell>{police.sigortaliAdUnvan}</TableCell>
    </TableRow>
  ))
))}
              </TableBody>
              </Table>
              </TableContainer>
          <Typography variant="h6" mt={4} gutterBottom>
            Kusur Değerlendirmeler
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                <TableCell>#</TableCell>
                  <TableCell>1.Araç</TableCell>
                  <TableCell>2.Araç</TableCell>
                 
           
                </TableRow>
              </TableHead>
              <TableBody>
              {kusurlar.map((kusur) => (
                
                <TableRow>
                  <TableCell>{kusur.satirSiraNo}</TableCell>
                  <TableCell>{kusur.kusurOrani1}</TableCell>
                  <TableCell>{kusur.kusurOrani2}</TableCell>
                </TableRow>
                ))}
              
              </TableBody>
            </Table>
          </TableContainer>
        </Container>
      </Box>
    </div>
  );
}
if(loading == false && server =='kttdb3'){
    return (
      <div>
        <Box
          sx={{
            background: 'linear-gradient(to bottom, #f0f0f0, #ffffff)',
            minHeight: '100vh',
            color: 'black',
            py: 4,
          }}
        >
          <Container maxWidth="lg">
            <Typography variant="h4" gutterBottom>
              Kaza Tespit Tutanağı Genel Bilgileri
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <strong>SBM Kaza İhbar No:</strong> {genelBilgiler.kazaSiraNo}
                    </TableCell>
                    <TableCell>
                      <strong>Kaza Tarihi:</strong> {genelBilgiler.kazaTarihi}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>İhbar Tarihi:</strong> {ihbar.ihbarTarihi}
                    </TableCell>
                    <TableCell>
                      <strong>İlk İşlem Tarihi:</strong>{genelBilgiler.ilkIslemTarihi}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Son İşlem Tarihi:</strong> {genelBilgiler.sonIslemTarihi}
                    </TableCell>
                    <TableCell>
                      <strong>Kaza Yeri İl/İlçe:</strong> 
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Hasarlı Araç Adedi:</strong> {genelBilgiler.kazaAracAdeti}
                    </TableCell>
                    <TableCell>
                      <strong>İhbarla İlgili Ürün:</strong> {ihbar.ihbariAlinanUrun == 100 && 'Trafik'} {ihbar.ihbariAlinanUrun == 120 && 'Kasko'}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>KTT Kayıt Tipi:</strong> {genelBilgiler.kaynakTipi}
                    </TableCell>
                    <TableCell>
                      <strong>Dosya Durumu:</strong> {genelBilgiler.dosyaDurumKodu}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
  
            <Typography variant="h6" mt={4} gutterBottom>
              <strong>Araç Bilgileri</strong>
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell><strong>#</strong></TableCell>
                    <TableCell><strong>Plaka</strong></TableCell>
                    <TableCell><strong>Şase No</strong></TableCell>
                    <TableCell><strong>Motor No</strong></TableCell>
                    <TableCell><strong>Sigortalı Vergi/Kimlik No</strong></TableCell>
                    <TableCell><strong>Sürücü İle Sigortalı Aynı Mı</strong></TableCell>
                    <TableCell><strong>Sürücü Kimlik No</strong></TableCell>
                    <TableCell><strong>Sürücü Ad Soyadı</strong></TableCell>
                    <TableCell></TableCell>
                
                  </TableRow>
                </TableHead>
                <TableBody>
                {araclar.map((arac) => (
                  
                  <TableRow>
                      <TableCell>{arac.aracSiraNo}</TableCell>
                    <TableCell>{arac.plakaIlKodu} {arac.plakaNo}</TableCell>
                    <TableCell>{arac.sasiNo}</TableCell>
                    <TableCell>{arac.motorNo}</TableCell>
                    <TableCell>{arac.sigortaliVatandaslikNo != 0 && arac.sigortaliVatandaslikNo}  {arac.sigortaliVergiNo != 0 && arac.sigortaliVergiNo}</TableCell>
                    <TableCell>{arac.surucuSigortaliIleAynimi == 'E' && 'Evet'}
                    {arac.surucuSigortaliIleAynimi == 'H' && 'Hayir'} 
                    </TableCell>
                    <TableCell>{arac.surucuSigortaliIleAynimi == 'H' && arac.surucuVatandaslikNo}</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                
                  </TableRow>
                
                  ))}
                   
  
                  
                </TableBody>
              </Table>
            </TableContainer>
            <Typography variant="h6" mt={4} gutterBottom>
              Poliçeler
            </Typography>
            <TableContainer component={Paper}>
            <Table>
                <TableHead>
                  <TableRow>
                  <TableCell><strong>Plaka</strong></TableCell>
                    <TableCell><strong>Ürün</strong></TableCell>
                    <TableCell><strong>Sigorta Şirket</strong></TableCell>
                    <TableCell><strong>Acente No</strong></TableCell>
                    <TableCell><strong>Poliçe No</strong></TableCell>
                    <TableCell><strong>Yenileme No</strong></TableCell>
                    <TableCell><strong>Poliçe Başlama Tarh</strong></TableCell>
                    <TableCell><strong>Poliçe Bitiş Tarih</strong></TableCell>
                    <TableCell><strong>Sigortalı</strong></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {araclar.map((arac) => (
    arac.policeler.map((police, index) => (
      <TableRow key={index}>
          <TableCell>{arac.plakaIlKodu} {arac.plakaNo}</TableCell>
        <TableCell>{police.urunKodu == 100 && 'Trafik'} {police.urunKodu == 120 && 'Kasko'}</TableCell>
        <TableCell>{police.sirketKodu}</TableCell>
        <TableCell>{police.acenteNo}</TableCell>
        <TableCell>{police.policeNo}</TableCell>
        <TableCell>{police.yenilemeNo}</TableCell>
        <TableCell>{police.policeBaslangicTarihi}</TableCell>
        <TableCell>{police.policeBitisTarihi}</TableCell>
        <TableCell>{police.sigortaliAdUnvan}</TableCell>
      </TableRow>
    ))
  ))}
                </TableBody>
                </Table>
                </TableContainer>
            <Typography variant="h6" mt={4} gutterBottom>
              Kusur Değerlendirmeler
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                  <TableCell>#</TableCell>
                    <TableCell>1.Araç</TableCell>
                    <TableCell>2.Araç</TableCell>
                   
             
                  </TableRow>
                </TableHead>
                <TableBody>
                {kusurlar.map((kusur) => (
                  
                  <TableRow>
                    <TableCell>{kusur.satirSiraNo}</TableCell>
                    <TableCell>{kusur.kusurOrani1}</TableCell>
                    <TableCell>{kusur.kusurOrani2}</TableCell>
                  </TableRow>
                  ))}
                
                </TableBody>
              </Table>
            </TableContainer>
          </Container>
        </Box>
      </div>
    );
  }
  if(loading == false && server =='kttdb4'){
    return (
      <div>
        <Box
          sx={{
            background: 'linear-gradient(to bottom, #f0f0f0, #ffffff)',
            minHeight: '100vh',
            color: 'black',
            py: 4,
          }}
        >
          <Container maxWidth="lg">
            <Typography variant="h4" gutterBottom>
              Kaza Tespit Tutanağı Genel Bilgileri
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <strong>SBM Kaza İhbar No:</strong> {genelBilgiler.tramer_ihbar_no}
                    </TableCell>
                    <TableCell>
                      <strong>Kaza Tarihi:</strong> {genelBilgiler.kaza_tarihi}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>İhbar Tarihi:</strong> {ihbar.ihbarTarihi}
                    </TableCell>
                    <TableCell>
                      <strong>İlk İşlem Tarihi:</strong>{genelBilgiler.ilkIslemTarihi}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Son İşlem Tarihi:</strong> {genelBilgiler.sonIslemTarihi}
                    </TableCell>
                    <TableCell>
                      <strong>Kaza Yeri İl/İlçe:</strong> 
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Hasarlı Araç Adedi:</strong> {genelBilgiler.kaza_arac_adet}
                    </TableCell>
                    <TableCell>
                      <strong>İhbarla İlgili Ürün:</strong> {genelBilgiler.brans_kod == 100 && 'Trafik'} {genelBilgiler.brans_kod == 120 && 'Kasko'}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>KTT Kayıt Tipi:</strong> {genelBilgiler.kaynakTipi}
                    </TableCell>
                    <TableCell>
                      <strong>Dosya Durumu:</strong> {genelBilgiler.durum_kod}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
  
            <Typography variant="h6" mt={4} gutterBottom>
              <strong>Araç Bilgileri</strong>
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell><strong>#</strong></TableCell>
                    <TableCell><strong>Plaka</strong></TableCell>
                    <TableCell><strong>Şase No</strong></TableCell>
                    <TableCell><strong>Motor No</strong></TableCell>
                    <TableCell><strong>Sigortalı Vergi/Kimlik No</strong></TableCell>
                    <TableCell><strong>Sürücü İle Sigortalı Aynı Mı</strong></TableCell>
                    <TableCell><strong>Sürücü Kimlik No</strong></TableCell>
                    <TableCell><strong>Sürücü Ad Soyadı</strong></TableCell>
                    <TableCell></TableCell>
                
                  </TableRow>
                </TableHead>
                <TableBody>
                {araclar.map((arac) => (
                  
                  <TableRow>
                      <TableCell>{arac.arac_sira_no}</TableCell>
                    <TableCell>{arac.plaka_no}</TableCell>
                    <TableCell>{arac.sasi_no}</TableCell>
                    <TableCell>{arac.motor_no}</TableCell>
                    <TableCell>{arac.policeler[0].sigortali_kimlik_no != 0 && arac.policeler[0].sigortali_kimlik_no} {arac.policeler[0].sigortali_vergi_no != 0 && arac.policeler[0].sigortali_vergi_no}</TableCell>
                    <TableCell>{arac.policeler[0].sigortali_kimlik_no == arac.surucu_kimlik_no && 'Evet'}
                    {arac.policeler[0].sigortali_kimlik_no != arac.surucu_kimlik_no && 'Hayir'} 
                    </TableCell>
                    <TableCell>{arac.surucu_kimlik_no}</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                
                  </TableRow>
                
                  ))}
                   
  
                  
                </TableBody>
              </Table>
            </TableContainer>
            <Typography variant="h6" mt={4} gutterBottom>
              Poliçeler
            </Typography>
            <TableContainer component={Paper}>
            <Table>
                <TableHead>
                  <TableRow>
                  <TableCell><strong>Plaka</strong></TableCell>
                    <TableCell><strong>Ürün</strong></TableCell>
                    <TableCell><strong>Sigorta Şirket</strong></TableCell>
                    <TableCell><strong>Acente No</strong></TableCell>
                    <TableCell><strong>Poliçe No</strong></TableCell>
                    <TableCell><strong>Yenileme No</strong></TableCell>
                    <TableCell><strong>Poliçe Başlama Tarh</strong></TableCell>
                    <TableCell><strong>Poliçe Bitiş Tarih</strong></TableCell>
                    <TableCell><strong>Sigortalı</strong></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {araclar.map((arac) => (
    arac.policeler.map((police, index) => (
      <TableRow key={index}>
          <TableCell>{arac.plaka_no}</TableCell>
        <TableCell>{police.brans_kod == 100 && 'Trafik'} {police.brans_kod == 120 && 'Kasko'}</TableCell>
        <TableCell>{police.sirket_kod_adi}</TableCell>
        <TableCell></TableCell>
        <TableCell>{police.police_no}</TableCell>
        <TableCell></TableCell>
        <TableCell>{police.baslama_tarihi}</TableCell>
        <TableCell>{police.bitis_tarihi}</TableCell>
        <TableCell>{police.sigortali_ad}</TableCell>
      </TableRow>
    ))
  ))}
                </TableBody>
                </Table>
                </TableContainer>
            <Typography variant="h6" mt={4} gutterBottom>
              Kusur Değerlendirmeler
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                  <TableCell>#</TableCell>
                    <TableCell>1.Araç</TableCell>
                    <TableCell>2.Araç</TableCell>
                   
             
                  </TableRow>
                </TableHead>
                <TableBody>
                {kusurlar.map((kusur) => (
                  
                  <TableRow>
                    <TableCell>{kusur.degid}</TableCell>
                    <TableCell>{kusur.kusur_orani1}</TableCell>
                    <TableCell>{kusur.kusur_orani2}</TableCell>
                  </TableRow>
                  ))}
                
                </TableBody>
              </Table>
            </TableContainer>
                
            <Typography variant="h6" mt={4} gutterBottom>
              Komisyon Değerlendirmeler
            </Typography>
            {kusurlar2.length > 0 & 
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                  <TableCell>#</TableCell>
                    <TableCell>1.Araç</TableCell>
                    <TableCell>2.Araç</TableCell>
                   
             
                  </TableRow>
                </TableHead>
                <TableBody>
                {kusurlar2.map((kusur) => (
                  
                  <TableRow>
                    <TableCell>{kusur.degid}</TableCell>
                    <TableCell>{kusur.kusur_orani1}</TableCell>
                    <TableCell>{kusur.kusur_orani2}</TableCell>
                  </TableRow>
                  ))}
                
                </TableBody>
              </Table>
            </TableContainer>
  }
          </Container>
        </Box>
      </div>
    );
  }
if(loading == false && server =='kttdb2'){
    return (
      <div>
        <Box
          sx={{
            background: 'linear-gradient(to bottom, #f0f0f0, #ffffff)',
            minHeight: '100vh',
            color: 'black',
            py: 4,
          }}
        >
          <Container maxWidth="lg">
            <Typography variant="h4" gutterBottom>
              Kaza Tespit Tutanağı Genel Bilgileri
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <strong>SBM Kaza İhbar No:</strong> {genelBilgiler.sbm_ktt}
                    </TableCell>
                    <TableCell>
                      <strong>Kaza Tarihi:</strong> {genelBilgiler.hasar_tarihi}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>İhbar Tarihi:</strong> {genelBilgiler.ihbar_tarihi}
                    </TableCell>
                  
                  </TableRow>
                  <TableRow>
                  
                    <TableCell>
                      <strong>Kaza Yeri İl/İlçe: {genelBilgiler.il} {genelBilgiler.ilce}</strong> 
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Hasarlı Araç Adedi:</strong> {araclar.length}
                    </TableCell>
                    <TableCell>
                      <strong>İhbarla İlgili Ürün:</strong> {genelBilgiler.brans}
                    </TableCell>
                  </TableRow>
     
                </TableBody>
              </Table>
            </TableContainer>
  
            <Typography variant="h6" mt={4} gutterBottom>
              <strong>Araç Bilgileri</strong>
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell><strong>Plaka</strong></TableCell>
                    <TableCell><strong>Şase No</strong></TableCell>
                    <TableCell><strong>Motor No</strong></TableCell>
                    <TableCell><strong>Sigortali</strong></TableCell>
                    <TableCell><strong>Sigortalı Vergi/Kimlik No</strong></TableCell>
                    <TableCell><strong>Sürücü İle Sigortalı Aynı Mı</strong></TableCell>
                    <TableCell><strong>Sürücü Kimlik No</strong></TableCell>
                    <TableCell><strong>Sürücü Ad Soyadı</strong></TableCell>
                    <TableCell></TableCell>
                
                  </TableRow>
                </TableHead>
                <TableBody>
                {araclar.map((arac) => (
                  
                  <TableRow>
                    <TableCell>{arac.plaka}</TableCell>
                    <TableCell>{arac.sase_no}</TableCell>
                    <TableCell>{arac.motor_no}</TableCell>
                    <TableCell>{arac.unvan}</TableCell>
                    <TableCell>{arac.kimlik_no}</TableCell>
                    <TableCell>{arac.kimlik_no == arac.surucu_kimlik && 'Evet'}
                    {arac.kimlik_no != arac.surucu_kimlik && 'Hayir'} 
                    </TableCell>
                    <TableCell>{arac.kimlik_no != arac.surucu_kimlik && arac.surucu_kimlik}</TableCell>
                    <TableCell>{arac.kimlik_no != arac.surucu_kimlik && arac.surucu_ad}</TableCell>
                    <TableCell></TableCell>
                
                  </TableRow>
                
                  ))}
                   
  
                  
                </TableBody>
              </Table>
            </TableContainer>
           
            <Typography variant="h6" mt={4} gutterBottom>
              Kusur Değerlendirmeler
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                  <TableCell>Plaka</TableCell>
                    <TableCell>Kusur</TableCell>
                   
             
                  </TableRow>
                </TableHead>
                <TableBody>
                {araclar.map((kusur) => (
                  
                  <TableRow>
                    <TableCell>{kusur.plaka}</TableCell>
                    <TableCell>{kusur.kusur_orani}</TableCell>
                  </TableRow>
                  ))}
                
                </TableBody>
              </Table>
            </TableContainer>
          </Container>
        </Box>
      </div>
    );
  }
}

export default KazaTespitTutanagi;
